import Vue from 'vue'
import Router from 'vue-router'
import Frontend from '@/components/frontend/Frontend'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      component: Frontend,
      children: [{
        path: ':season',
        name: 'Buchungsformular',
        component: Frontend
      }]
    }
  ]
})
